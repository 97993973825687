import Vue from 'vue'
import Router from 'vue-router'

import Auth from './Auth'
import Dashboard from './Dashboard'
import { ROUTE_REMOVE_ACCOUNT } from '@/constants'

Vue.use(Router)

export default new Router({
  mode: 'history',

  routes: [
    Auth,
    Dashboard,

    {
      path: '/remover-conta',
      name: ROUTE_REMOVE_ACCOUNT,
      component: () => import(/* webpackChunkName: "account-remove" */ '@/views/Public/AccountRemove')
    },

    {
      path: '/configuracao/:configId/:configType',
      component: () => import(/* webpackChunkName: "config-drag-drop" */ '@/views/Public/ConfigDragDrop')
    },

    {
      path: '/player',
      component: () => import(/* webpackChunkName: "player" */ '@/views/Public/AudioPlayer')
    }
  ]
})
